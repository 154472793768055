/*
 * Set mailing panels size
 */
.mailing-wizard-panel {
  width: 80%;
}

.mailing-wizard-sending-panel {
  width: 50%;
}

/* Remove flashy decoration from buttons in download panel */
.mailing-wizard-sending-cancel:focus {
  box-shadow: 0 0 0 0 !important;
}

/*
 * Limit panel attachment list height
 */
.mailing-wizard-panel .p-dialog-content {
  max-height: 80vh;
  overflow-y: auto;
}

/*
 * Field selector block
 */
.mailing-wizard-selector-block .p-button {
  color: #000000 !important;
  border: none !important;
  background: transparent !important;
  margin-top: 0.2em !important;
}

.mailing-wizard-selector-block:hover .p-button {
  color: #007ad9 !important;
}

.mailing-wizard-selector-block .p-menuitem-text:hover {
  color: #007ad9 !important;
}

.mailing-wizard-selector-block .p-menuitem-link:hover {
  background: transparent !important;
}

.mailing-wizard-selector-block .p-splitbutton-menubutton:focus {
  border: none !important;
}

.mailing-wizard-selector-block .p-button:focus {
  box-shadow: 0 0 0 0 !important;
}

.mailing-wizard-selector-block .p-button-text {
  margin-top: -0.3em !important;
}

.mailing-wizard-frame {
  margin-bottom: -0.8em !important;
}

/*
 * Icons buttons G (bold) I (Italic) S (Underline)
 */
.mailing-wizard-frame .ql-stroke {
  fill: #000000 !important;
  font-size: 24px;
}

.mailing-wizard-frame .ql-stroke:hover {
  fill: #007ad9 !important;
}

.mailing-wizard-frame .ql-active .ql-stroke {
  fill: #007ad9 !important;
}

.mailing-wizard-frame .ql-bold .ql-stroke {
  font-weight: bold !important;
  stroke: none !important;
}

.mailing-wizard-frame .ql-italic .ql-stroke {
  font-style: italic !important;
  stroke: none !important;
}

.mailing-wizard-frame .ql-underline .ql-stroke {
  text-decoration: underline !important;
  stroke: none !important;
}

/*
 * Set editor text in grey when disabled
 */
.mailing-wizard-editor .ql-disabled .ql-editor {
  color: #aaaaaa !important;
}

/*
 * Hide preview (It is not present for all filetype showing unaligned items)
 * This class is not natively present in fileUploader it must be added with JS
 */
.mailing-wizard-fileupload-preview-img {
  display: none !important;
}

/*
 * Templates selector block
 */

.mailing-wizard-template-button {
  background: #007ad9 !important;
  border: 1px solid #007ad9 !important;
  padding: 0 !important;
  margin-top: 0.7em !important;
  height: 80% !important;
  width: 100% !important;
}

.mailing-wizard-template-button:hover {
  color: #ffffff !important;
  background: #0061c0 !important;
}

.mailing-wizard-template-button:focus {
  color: #ffffff !important;
}

.mailing-wizard-template-block .p-dropdown {
  padding: 0 !important;
  margin-top: 0.7em !important;
  width: 100% !important;
}

.mailing-wizard-template-block div {
  padding: 0.3 !important;
}

.mailing-wizard-template-block {
  margin-top: -1.2em !important;
}





