/* Add botom line separator wetween attachments */
.attachments-item:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
}

/* Set upload panel size */
.attachments-confirm-panel {
  width: 70%;
}

/* Set download panel size */
.attachments-download-panel {
  width: 70%;
}

/* Limit panel attachment list height */
.attachments-confirm-panel .p-dialog-content {
  max-height: 80vh;
  overflow-y: auto;
}

/* Remove flashy decoration from buttons in upload panel */
.attachments-confirm-panel .p-button:focus {
  box-shadow: 0 0 0 0 !important;
}

/* Remove flashy decoration from buttons in download panel */
.attachments-download-cancel:focus {
  box-shadow: 0 0 0 0 !important;
}

/* Reduce error message container size */
.attachments-error {
  height: 85%;
}

/* Set error message display place */
.attachments-error .p-button-text {
  margin-top: -6px;
}

/* Do not opacify inputs in read only forms */
.attachments-input.p-disabled {
  opacity: 1 !important;
}

/* Set bold red text labels */
.attachments-label-missing-field {
  color: red !important;
  font-weight: bold !important;
}

/* Set red border on inputs */
.attachments-label-target-missing-field {
  border-color: red !important;
}

/* Replace blue shadow by red one on inputs */
.attachments-label-target-missing-field:focus {
  box-shadow: 0 0 0 0.2em #ff899d !important;
}

/* Add spaces between actions buttons */
.attachments-actions-container>.p-button {
  margin-left: 5px;
  margin-bottom: 5px;
}

/* Add pponter cursor on fake buttons */
.attachments-cursor-pointer {
  cursor: pointer;
}
