.splash-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    @include linear-gradient-left($splashStartColor, $splashFinishColor);

    .splash-container {
        width: 40px;
        height: 40px;
        margin: 0px auto;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -20px;
        margin-top: -20px;

    }

    .splash-double-bounce1, .splash-double-bounce2 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $splashFlashColor;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;

        -webkit-animation: splash-bounce 2.0s infinite ease-in-out;
        animation: splash-bounce 2.0s infinite ease-in-out;
    }

    .splash-double-bounce2 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }

    @-webkit-keyframes splash-bounce {
        0%, 100% { -webkit-transform: scale(0.0) }
        50% { -webkit-transform: scale(1.0) }
    }

    @keyframes splash-bounce {
        0%, 100% {
            transform: scale(0.0);
            -webkit-transform: scale(0.0);
        } 50% {
              transform: scale(1.0);
              -webkit-transform: scale(1.0);
          }
    }
}
