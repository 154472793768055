/*
 * Global application layout wrapper
 */

.layout-wrapper {
    position: relative;
    min-height:100%;
    padding: 0;

    @import "_sideBar";
    @import "_main";
    @import "_footer";

}

/*
 * Large screens :
 *  - display sidebar by default
 *  - hide it on demand
 */
@media (min-width: 1025px) {
    .layout-wrapper {

        .layout-sidebar {
            left: 0;
        }

        .layout-main, .layout-footer {
            margin-left: 250px;
        }

        &.layout-static-sidebar-inactive {
            .layout-sidebar {
                left: -250px;
            }

            .layout-main, .layout-footer {
                margin-left: 0;
            }
        }
    }
}

/*
 * Small screens :
 *  - do not display sidebar by default
 *  - show it on demand
 *  - hide content overflow
 */
@media (max-width: 1024px) {
    .layout-wrapper {

        .layout-main, .layout-footer {
            margin-left: 0;
        }

        .layout-sidebar {
            left: -250px;
            margin-top: 50px;
        }

        &.layout-mobile-sidebar-active {
            .layout-sidebar {
                left: -0;
            }

            .layout-mask {
                display: block;
            }
        }
    }

    .body-overflow-hidden {
        overflow: hidden;
    }
}

