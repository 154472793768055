/* General */
$fontSize:14px;

/* Login form */
$loginBgColor:#ff7b00;

/* Main Body */
$bodyBgColor:#ffffff;
$textColor:#333333;
$borderRadius:3px;
$transitionDuration:.2s;

/* Menu */
$scrollPanelBgColor:#ffffff;                  // Scrollbar if menu is heigher than the screen
$menuitemBadgeBgColor:#007be5;                // Optional menu item badge
$menuitemBadgeColor:#ffffff;
$submenuFontSize:13px;                        // Menu font
$menuBgColorFirst:#ff7b00;                    // Menu Color (may be gradient)
$menuBgColorLast:#ff7b00;
$menuitemColor:#ffffff;                       // Item (text and icons) colors
$menuitemHoverColor:#ffea00;
$menuitemActiveColor:#ffea00;
$menuitemActiveBgColor:#ffaa00;
$menuitemBorderColor:rgba(52, 56, 65, 0.6);   // Top border of each menu entry

/* Splash screen */
$splashStartColor:#ff7b00;
$splashFinishColor:#C4DEFC;
$splashFlashColor:#ffffff;

/* Footer */
$footerBgColor:#ffffff;

@import "_mixins";
@import "_login";
@import "_splash";
@import "_global";
@import "_wrapper";
