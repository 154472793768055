/*
 * Main panel layout
 */

.layout-main {
    @include transition(margin-left $transitionDuration);
    padding: 1px 8px 0px 8px;
    min-height: 100vh;
}

