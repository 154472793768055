/*
 *  Footer panel layout
 */

.layout-footer {
    @include transition(margin-left $transitionDuration);
    background-color: $footerBgColor;
    padding: 1em 2em;
    // keep footer at the bottom of the page
    position: absolute;
    bottom: 0;

    img {
        vertical-align: middle;
    }

    .footer-text {
        vertical-align: middle;
    }
}
