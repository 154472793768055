
/*
 *  Forms styling custom options
 */


/* Do not opacify inputs in read only forms */
.form-input.p-disabled {
  opacity: 1 !important;
}

/* Do not opacify dropdown in read only forms */
.form-input > .p-disabled {
  opacity: 1 !important;
}

/* Make space above (new line like) */
.form-new-line {
  margin-top: 30px;
}

/* Set bold red text labels */
.form-label-missing-field {
  color: red !important;
  font-weight: bold !important;
}

/* Set red border on inputs */
.form-label-target-missing-field {
  border-color: red !important;
}

/* Replace blue shadow by red one on inputs */
.form-label-target-missing-field:focus {
  box-shadow: 0 0 0 0.2em #ff899d !important;
}

/* Set red border on calendar inputs */
.form-label-target-missing-field > .p-inputtext {
  border-color: red !important;
}

/* Replace blue shadow by red one on calendar inputs */
.form-label-target-missing-field > .p-inputtext:focus {
  box-shadow: 0 0 0 0.2em #ff899d !important;
}

/* Replace blue background and border by red ones on calendar button */
.form-label-target-missing-field > .p-button {
  background-color: red !important;
  border-color: red !important;
}

/* Replace blue shadow by red one on calendar button */
.form-label-target-missing-field > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2em #ff899d !important;
}

/* Replace blue shadow by red one on dropdowns */
.form-label-target-missing-field.p-dropdown:not(p-disabled).p-focus {
  box-shadow: 0 0 0 0.2em #ff899d !important;
}

/* Remove flashy highlight from tabs */
.p-tabview-nav a {
  box-shadow: 0 0 0 0 !important;
}

/* Use button to display counter :-) */
.form-input-item-counter {
  margin: 0 2px;
}
.form-input-item-counter > .p-button {
  opacity: 1 !important;
}

@media (min-width: 768px) {
  .form-text-align-md-right {
    text-align: right;
  }
  .form-text-align-md-center {
    text-align: center;
  }
}

/* chache un élément */
.form-hidden {
  visibility:hidden !important
}

/* Font awesome button */
.form-fa-button {
  color: #007ad9;
}


