
.globalsearch-results ul {
  list-style-type: none;
}

.globalsearch-results-title {
  font-weight: bold;
}

/* Add pointer cursor on fake buttons */
.globalsearch-cursor-pointer {
  cursor: pointer;
}
