/*
 * Login form
 */

.login-center{
    font-family: 'Raleway', sans-serif;
    background-color: $loginBgColor;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-dialog-mask.login-mask{
  background-color: $loginBgColor;
}

.login-card{
    background-color: #fff;
    border-radius: 15px;
    padding: 0.8rem;
}

.login-card > form{
    display: flex;
    flex-direction: column;
}

.login-card h1{
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.login-form-item{
    font-family: 'Raleway', sans-serif;
    padding: 5px;
    margin-bottom: 2rem;
    height: 30px;
    width: 16rem;
    border: 1px solid grey;
}

.login-form-submit{
    font-family: 'Raleway', sans-serif;
    height: 35px;
    color: #fff;
    background-color: #07BDF4;
    border: none;
    letter-spacing: 0.2rem;
    transition: 0.3s opacity ease;
    cursor: pointer;
}

.login-form-submit:hover{
   opacity: 0.6;
}

