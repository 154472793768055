/*
 * Side bar layout
 */

.layout-sidebar {
    position: fixed;
    margin-top: 0px;
    width: 250px;
    height: 100%;
    z-index: 999;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    @include transition(left $transitionDuration);
    @include linear-gradient($menuBgColorFirst, $menuBgColorLast);
    @include shadow(0 0 6px 0 rgba(0, 0, 0, 0.16));


    .p-scrollpanel {
        background: transparent;
        border-radius: 0;
        border: none;

        .p-scrollpanel-content {
            overflow: scroll;
        }

        .p-scrollpanel-bar {
            background: $scrollPanelBgColor;
            @include opacity(0.3);
        }

        .p-scrollpanel-hidden {
            display: block;
            visibility: hidden;
        }

        .layout-sidebar-scroll-content {
            width: calc(100% + 18px);
            padding-bottom: 120px;
        }
    }

    .layout-menu-button-container {
      display: flex;
      margin-right: 10px;
      justify-content: flex-end;

      .layout-menu-button {
        cursor: pointer;
        margin-right: 10px;
        margin-top: 10px;
        text-decoration: none;
        color: $menuitemColor;
        transition: color 0.2s;

        &:focus {
          box-shadow: 0 0 0 0px $menuitemActiveBgColor;
        }
      }
    }


    .layout-logo {
        text-align: center;
        margin-top: 24px;
    }

    .profile {
        text-align: center;
        padding: 20px 0;

        img {
            width: 56px;
            margin: 10px;
        }

        .profile-link {
            cursor: pointer;
            color: $menuitemColor;
            display: inline-block;
            margin-bottom: 10px;
            @include transition(color $transitionDuration);

            i {
                display: inline-block;
                font-size: 16px;
                vertical-align: middle;
            }

            &:hover {
                color: $menuitemHoverColor;
            }

            &:focus {
              box-shadow: 0 0 0 0px $menuBgColorFirst;
            }
        }

        > ul {
            overflow: hidden;
            background-color: $menuitemActiveBgColor;
            text-align: left;
            max-height: 0;
            @include transition-duration($transitionDuration);
            @include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));

            &.profile-expanded {
                max-height: 500px;
                border-bottom: 1px solid $menuitemBorderColor;
            }

            li {
                button {
                    width: 100%;
                    padding: 1em 15px 1em 1em;
                    border-top: 1px solid $menuitemBorderColor;
                    border-radius: 0;

                    &:hover {
                        color: $menuitemHoverColor;
                    }

                    span {
                        margin-left: .25em;
                        vertical-align: middle;
                    }

                    i {
                        vertical-align: middle;
                    }
                }

            }

        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            a {
                cursor: pointer;
                position: relative;
                color: $menuitemColor;
                text-decoration: none;
                font-size: $fontSize;
                padding: 1em 15px 1em 1em;
                display: block;
                border-top: 1px solid $menuitemBorderColor;

                i {
                    font-size: 18px;
                    vertical-align: middle;
                }

                span {
                    margin-left: .25em;
                    vertical-align: middle;
                }

                .menuitem-toggle-icon {
                    float: right;
                }

                &:hover {
                    color: $menuitemHoverColor;
                }

                &.active-route {
                    background-color: $menuitemActiveBgColor;
                    color: $menuitemActiveColor;

                    .menuitem-toggle-icon {
                        @include icon-override("\e933");
                    }
                }
            }

            &.active-menuitem {
                > a {
                    background-color: $menuitemActiveBgColor;
                    color: $menuitemActiveColor;

                    .menuitem-toggle-icon {
                        @include icon-override("\e933");
                    }
                }

                > ul {
                    max-height: 5000px;
                }
            }

            ul {
                background-color: $menuitemActiveBgColor;
                overflow: hidden;
                padding-left: 1.5em;
                max-height: 0;
                @include transition-property(max-height);
                @include transition-duration(0.4s);
                @include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));

                li {
                    a {
                        cursor: pointer;
                        padding: .75em 33px .75em .75em;
                        font-size: $submenuFontSize;
                        border-top: 0 none;
                    }

                    &:last-child {
                        > a {
                            border-bottom: 0 none;
                        }
                    }
                }
            }

            &:last-child {
                > a {
                    border-bottom: 1px solid $menuitemBorderColor;
                }
            }
        }

        .menuitem-toggle-icon {
            float: right;
            margin-top: 2px;
        }

        .menuitem-badge {
            margin-top: 3px;
            font-size: 10px;
            float: right;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            color: $menuitemBadgeColor;
            background-color: $menuitemBadgeBgColor;
            @include border-radius(50%);
        }
    }

}


