// Compute the size of each elements from its outer total size
* {
    box-sizing: border-box;
}

// Set a reference for children relative sizes
// Html uses 100% of the total viewport height
html {
    height: 100%;
}

// Apply the global style configuration
// and have the body minimum heigh tahe the whole vieport (100% of the html parent)
body {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: $fontSize;
    color: $textColor;
    background-color: $bodyBgColor;
    margin: 0;
    padding: 0;
    min-height: 100%;
}

// Remove underlines from links
a {
    text-decoration: none;
}

// Titles typography
h1 {
    font-weight: normal;
    margin: 0;
    font-size: 24px;
}

h2 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
}

// Card components
.card {
    background-color: #ffffff;
    padding: 1em;
    margin-bottom: 16px;
    min-height: 100%;
    @include border-radius($borderRadius);

    &.card-w-title {
        padding-bottom: 2em;
    }

    h1 {
        margin: .5em 0 .5em 0;
        border-bottom: 1px solid #d5d5d5;
        padding: .1em;
        font-size: 24px;

        &:first-child {
            margin: 0 0 .5em 0;
        }
    }
}


