/*
 * Display company detailed informations
 * On mouse over in the Contact form title
 */

.contact-form-company-block .contact-form-company-info-block {
  display: none;
}

.contact-form-company-block:hover .contact-form-company-info-block {
	display: block;
}

