
/*
 *  Organigram styling custom options
 */


/* Top node */
.organigram-node-top {
	/*background-color: #495ebb !important;*/
	background-color: #66A7E1 !important;
	color: #fff !important;
  cursor: pointer !important;
}

/* Classic node */
.organigram-node {
	/*background-color: #7247bc !important;*/
	background-color: #66A7E1 !important;
	color: #fff !important;
  cursor: pointer !important;
}

/* Leaf node */
.organigram-node-leaf {
	/*background-color: #e9286f !important;*/
	background-color: #66A7E1 !important;
	color: #fff !important;
  cursor: pointer !important;
}

/* Current user's node */
.organigram-node-current {
	background-color: #495ebb !important;
  /*border: 2px solid #18395E !important;*/
  font-weight: bold;
  font-style: italic;
}




