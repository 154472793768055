
/*
 *  Scrollers styling custom options
 */

/* Pointer */
.p-datascroller-content {
  cursor: pointer;
}

/* Icons styling*/
.scroller-icon {
  font-size: 0.6em;
  margin-right: 1em;
  color: #555555;
}

/* Align text on left (used in headers) */
.scroller-text-left {
  text-align: left;
}

/* Scroller items separator */
.scroller-item-separator {
  border-bottom: 1px solid #d5d5d5 ;
  margin: 0.1em;
}

/* Auto clamp slider main content */
.scroller-item-content {
  white-space: pre-wrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
  -webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

/* Ewpand slider main content on mouse over */
.scroller-item-content:hover {
	overflow: visible;
  display: block;
}

